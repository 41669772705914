$input-background: rgba(243, 243, 243, 0.8);

@keyframes gradient {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 100% 0;
	}
}

.topbar {
	a {
		margin-left: 3em;
	}
	> div {
		margin: 0 auto;
	}
}

.search {
	display: flex;
	width: 40ch;
	height: 2.5em;
	z-index: 1;
	background-color: $input-background;
	background-image: url("/assets/search.svg/");
	background-repeat: no-repeat;
	background-size: 2em;
	background-position: 95%;
	border: none;
	//border: black 1px solid;
	border-radius: 2px;
	text-indent: 1em;
	//box-shadow: 2px 2px 2px 1px #00000026;
	padding-right: 4em;
	margin-right: 5.5em;
	::placeholder {
		padding: 0.5em;
		text-transform: uppercase;
	}
}

.search:after {
	content: "";
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999;
	height: 2px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	background-position: 0% 0%;
	background: linear-gradient(
		to right,
		#b294ff,
		#57e6e6,
		#feffb8,
		#57e6e6,
		#b294ff,
		#57e6e6
	);
	background-size: 500% auto;
	animation: gradient 3s linear infinite;
}

@media screen and (max-width: 50em) {
	.search {
		width: 17em;
		margin-right: 2em;
	}

	.topbar a {
		margin-left: 1em;
	}
}

@media screen and (max-width: 450px) {
	.search {
		width: 12em;
		margin-right: 2em;
	}

	.topbar a {
		margin-left: 1em;
	}
}

@media screen and (max-width: 360px) {
	.search {
		width: 15em;
		font-size: 10px;
		margin-top: 0.4em;
	}
	.topbar a {
		margin-left: 0.2em;
	}
}
