@use "../abstracts/colors" as *;
@use "../abstracts/fonts" as *;
@use "../../styles/css/abstracts/fonts" as *;

@font-face {
	font-family: "aktiv-grotesk-BoldCD";
	src: url("../../styles/css/fonts/AktivGroteskCD/BoldCD.otf");
	font-stretch: 1% 500%;
}

@font-face {
	font-family: "aktiv-grotesk-MediumCD";
	src: url("../../styles/css/fonts/AktivGroteskCD/RegularCD.otf");
	font-stretch: 1% 500%;
}

@font-face {
	font-family: "aktiv-grotesk-LightCD";
	src: url("../../styles/css/fonts/AktivGroteskCD/LightCD.otf");
	font-stretch: 1% 500%;
}

.court-detail {
	// margin-top: 10vh;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	flex: 1;
	margin: auto;
	margin-top: 10vh;
	height: 80vh;
	width: 80vw;
	background-color: black;
	z-index: o;
	.left,
	.right {
		gap: 1em;
		img {
			width: 100%;

			object-fit: cover;
		}
		.title {
			color: orange;
			font-family: "aktiv-grotesk-MediumCD";
			font-size: 2rem;
			margin-bottom: auto;
		}
		.history {
			font-size: 1em !important;
			font-family: "aktiv-grotesk-LightCD";
			p {
				strong {
					color: orange;
				}
				a {
					color: orange;
					text-transform: underline;
				}
			}
			strong {
				color: orange;
			}
			a {
				color: orange;
				text-transform: underline;
			}
		}
		.detail {
			color: white;
			font-size: 2em;
			font-family: "aktiv-grotesk-MediumCD";

			// text-transform: uppercase;
			strong {
				color: orange;
			}
			a {
				color: orange;
				text-transform: underline;
			}
			p {
				margin-bottom: 1em;
				strong {
					color: orange;
				}
				a {
					color: orange;
					text-transform: underline;
				}
			}
			img {
				width: calc(100% + 4em);
				// transform: translateX(-2em);
				margin-left: -2em;
			}
		}
		display: flex;
		flex: 0.5;
		width: 50%;
		flex-direction: column;
	}

	.left {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		img {
			width: 100%;
			height: 100%;
		}
		.close-btn {
			width: 4em;
			height: 4em;
			position: absolute;
			top: -2em;
			right: -2em;
		}

		.icons-parent {
			position: relative;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: flex-end;
			background-image: linear-gradient(transparent 60%, black);
			.left-icons {
				padding: 1em;
				display: flex;
				position: relative;
				width: 100%;
				height: 100%;
				flex-direction: row;
				justify-content: center;
				align-items: flex-end;
				&-div {
					display: flex;
					justify-content: space-around;
					align-items: center;
					width: 9em;
					a {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-decoration: none;

						img {
							width: 5em;
							height: 5em;
						}
						.icon-text-div {
							text-align: center;
							color: white;
							font-family: "Roboto-medium";
							font-size: inherit;
						}
					}
				}
			}

			/*.right-icons {
				padding: 1em;
				display: flex;
				position: relative;
				width: 100%;
				height: 100%;
				flex-direction: column;
				justify-content: end;
				align-items: end;

				&-div {
					display: flex;
					justify-content: center;
					align-items: center;
					a {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						text-decoration: none;
						img {
							width: 5em;
							height: 5em;
						}
						.icon-text-div {
							width: 3em;
							text-align: center;
							color: white;
							font-family: "Roboto-medium";
						}
					}
				}
			}*/
		}
	}
	.right {
		padding: 2em;
		overflow-y: scroll;

		.court-detail-double {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.detail-align-center {
				justify-content: center;
			}
		}

		.court-detail-info {
			display: flex;
			flex-direction: column;
			gap: 0.5em;

			.detail-location {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				&-text {
					margin-right: 1em;
				}
				&-icon {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					min-width: 3em;
					&-text {
						font-size: 0.5em;
					}
				}
			}
		}
	}
}

// .court-container > * {
// 	filter: blur(8px);
// }
.court-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	backdrop-filter: blur(3px) brightness(0);
}

// .disableblur {
// 	filter: blur(0);
// }

.by-hoopers {
	color: white;
	font-family: "aktiv-grotesk-LightCD";
}
.court-detail-info-title {
	color: orange;
	font-family: "aktiv-grotesk-MediumCD";
	font-size: 3em;
	margin-top: 0;
}

.court-detail-info {
	img {
		width: 100%;
	}
}

@media screen and (max-width: 1400px) {
	.icons-parent {
		.left-icons {
			&-div {
				a {
					.icon-text-div {
						font-size: 13px !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1260px) {
	.court-container {
		backdrop-filter: brightness(0);
	}

	.court-detail {
		flex-direction: column;
		height: 100vh;
		margin: 0;
		width: 100vw;
		.left,
		.right {
			width: 100%;
			height: 100%;
			align-self: center;
			.history {
				font-size: 1.25em !important;
			}
		}
		.left {
			overflow: hidden;
		}
	}
	.court-detail-info-title {
		font-size: 2em;
	}
	.court-detail-info {
		.title {
			font-size: 2em !important;
		}
		font-size: 0.7em;
	}
	.confirmation {
		font-size: 1.8em !important;
	}
	.btn {
		font-size: 0.9em !important;
	}
	.thank {
		font-size: 1em !important;
	}
	.right {
		width: 80% !important;
	}
	.close-btn {
		top: 2em !important;
		right: 2em !important;
	}
}

@media screen and (max-width: 470px) {
	.icons-parent {
		.left-icons {
			&-div {
				a {
					.icon-text-div {
						font-size: 10px !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 343px) {
	.icons-parent {
		.left-icons {
			&-div {
				a {
					.icon-text-div {
						font-size: 9px !important;
					}
				}
			}
		}
	}
}
