@use "./abstracts/colors" as *;
@use "./abstracts/fonts" as *;

html,
body {
	overflow: hidden;

	* {
		margin: 0;
		padding: 0;
	}
}

// div {
// 	border: 1px solid yellow;
// }

.app {
	display: flex;
	flex-direction: column;
	height: 100vh;
	position: relative;
}
