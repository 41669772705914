.mapContainer {
	height: 100vh;
	width: 100vw;
}

.mapicon {
	img {
		width: 4em;
		height: 4em;
		transform: translate(-50%, -50%);
	}
}
.cluster-marker {
	color: orange;
	background: black;
	font-size: 1.5em;
	border-radius: 50%;
	padding: 0.7em;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translate(-50%, -50%);
}

.location {
	position: absolute;
	right: 0.5em;
	bottom: 25%;
	width: 3.5em;
	height: 3.5em;
	background-color: transparent;
	border-color: transparent;
	img {
		width: 100%;
		height: 100%;
	}
}
.location-small {
	background-color: transparent;
	border-color: transparent;
	position: absolute;
	left: 5em;
	bottom: 25%;
	width: 3.5em;
	height: 3.5em;
	img {
		width: 100%;
		height: 100%;
	}
}
